<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <agent-partner />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import AgentPartner from '@/components/help-desk/agent-partner';
import ViewSkeleton from '@/components/utils/view-skeleton';
import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ViewSkeleton,
    AgentPartner,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.agentPartner'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
};
</script>
